<template>
  <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
    <article class="prose-sm prose-green" v-html="markdown">
    </article>
  </div>
</template>

<script>
const text =
`# About
## Itengrated Analytics

kaisekit Analyticsは、Webマスターのための統合解析ツールです。

通常のアクセス解析だけでなく、Google Search ConsoleからのSEOデータのインポート、インポート下データを元に、SEOランクチェックやトラッキングが可能です。

## You can use Free

kaisekit Analyticsは無料です。将来的により高度な機能をサブスクモデルとしてリリースする可能性はありますが、基本的な機能は無料で提供し続ける予定です。
`;

import { marked } from 'marked'

export default {
  name: 'About',
  setup() {
    const markdown = marked(text)
    return {
      markdown
    }
  }
}
</script>
